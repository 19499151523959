// import constant
import {
    SET_ACCOUNT_CONNECT_WALLET,
} from '../constant';

const initialState = {
    isWalletConn: false,
    address: '',
    balance: 0,
    currency: '',
    wallet: '',
    networkVersion: '',
    chainIndex: -1,
    singleUrl: '',
    multipleUrl: '',
    checked: false,
};

const account = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACCOUNT_CONNECT_WALLET:
            return {
                ...state,
                ...action.data
            }
        default:
            return state;
    }

}

export default account;