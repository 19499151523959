const {
    REACT_APP_MODE: envMode = 'development',
    REACT_APP_FRONT_URL: FRONT_URL,
    REACT_APP_API_URL: API_URL,
    REACT_APP_IMG_URL: IMG_URL
} = process.env;

console.log(`Set ${envMode} Config`);

let key = {
    API_URL,
    IMG_URL,
    FRONT_URL,
    explorerLink: 'https://rinkeby.etherscan.io/',
    SECRET_KEY: {
        JWT: 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3'
    },
    fixed: {
        usdprice: 3,
        currencyBal: 4
    },
    decimal: {
        value: 1e18
    },
}

export default key;

// export default {
//     ...key,
//     ...{ SITE_DETAIL: require('./siteConfig') }
// };