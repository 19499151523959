// import package
import jwt from "jsonwebtoken";

// import config
import config from 'src/core/config';

// import lib
import { getAuthToken } from '../lib/localStorage';
import { isEmpty } from '../lib/typeCheck';

export const isLogin = () => {
    let token = getAuthToken()
    if (!isEmpty(token)) {
        const jwtData = decodeJwt(token)
        if (jwtData.status) {
            return true
        }
        return false
    }
    return false;
}

export const decodeJwt = (token) => {
    try {
        if (!isEmpty(token)) {
            token = token.replace('Bearer ', '')
            const decoded = jwt.verify(token, config.SECRET_KEY.JWT);
            if (decoded) {
                return {
                    status: true
                }
            }
            return {
                status: false
            }
        }
    } catch (err) {
        return {
            status: false
        }
    }
}