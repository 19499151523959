import React from 'react'
import { HashRouter, Switch } from 'react-router-dom'
import { ToastContainer } from "react-toastify";
import './scss/style.scss'

// import redux
import { Provider } from 'react-redux';
import store from './core/redux/store';

// import component
import ConditionRoute from './components/Route/ConditionRoute'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  return (
    <Provider store={store}>
      <HashRouter>
        <React.Suspense fallback={loading}>
          <ToastContainer />
          <Switch>
            <ConditionRoute exact path="/login" name="Login Page" component={Login} type={'auth'} />
            <ConditionRoute exact path="/register" name="Register Page" component={Register} type={'auth'} />
            <ConditionRoute exact path="/404" name="Page 404" component={Page404} type={'public'} />
            <ConditionRoute exact path="/500" name="Page 500" component={Page500} type={'public'} />
            <ConditionRoute path="/" name="Home" component={DefaultLayout} type={'private'} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </Provider>
  )
}

export default App;