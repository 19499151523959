import { combineReducers } from "redux";

import account from './account';
import modal from './modal';
import chain from './chain';
import token from './token';
import {
    read,
    common
} from './common';

export default combineReducers({
    account,
    read,
    common,
    modal,
    chain,
    token
});