import { SET_TOKEN_DATA } from '../constant';

const token = (state = {data: []}, action) => {
    switch (action.type) {
        case SET_TOKEN_DATA:
            return {
                ...state,
                data: action.data
            }
        default:
            return state;
    }

}

export default token;