// Account
export const SET_ACCOUNT_CONNECT_WALLET = 'SET_ACCOUNT_CONNECT_WALLET';

// Common
export const SET_READ_DATA = 'SET_READ_DATA';
export const SET_COMMON_DATA = 'SET_COMMON_DATA';
export const SET_CHAIN_DATA = 'SET_CHAIN_DATA';
export const SET_TOKEN_DATA = 'SET_TOKEN_DATA';

// Modal
export const OPEN_CONNECT_WALLET = 'OPEN_CONNECT_WALLET';
export const CLOSE_CONNECT_WALLET = 'CLOSE_CONNECT_WALLET';